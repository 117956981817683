<template>
  <Details
    title="Community"
    resource="customerCommunities"
    :base-path="basePath"
    :request-params="{customerId: customerId}"
  >
    <template v-slot:default="detailsProps">
      <CommunityForm
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import CommunityForm from '@/views/auth/customers/communities/CommunityForm';
  import Details from '@/components/auth/details/Details';

  export default {
    name: 'CommunitiesCreate',
    components: {
      CommunityForm,
      Details,
    },
    computed: {
      customerId: function () {
        return this.$route.params.customerId;
      },
      basePath: function () {
        return this.$route.name.replace('create', 'index');
      }
    }
  };
</script>

